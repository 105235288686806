import React, { useState, useEffect } from 'react';
import firebase from '../../Config';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, TextField } from '@mui/material'
import { Circles } from 'react-loader-spinner'
import { loginSuccess } from '../../state/authAction'
const database = firebase.database();

let adminUid = {}
// const adminUid = 'wXxgF8zjX0bNsaavRCQA5UDIowy1';

const auth = getAuth();
// signInWithEmailAndPassword(auth, email, password)
//     .then((userCredential) => {
//         // Signed in
//         const user = userCredential.user;
//         // ...
//     })
//     .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//     });


function Login({ onLogin }) {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    let [error, setError] = useState(false)
    const [loader, setLoader] = useState(false)

    const [userData, setUserData] = useState({
        email: '',
        password: ''
    })


    /////////////////////////// fetch admin data from firebase //////////////////////





    //////////////////////// save the localStorage //////////////////
    // useEffect(() => {
    //     // Check if credentials are stored in localStorage
    //     const storedUid = localStorage.getItem('uid');

    //     if (storedUid) {
    //         if (storedUid === adminUid) {
    //             console.log("storeEmail:and password:", storedUid)
    //             navigate('/team')
    //         }
    //     }
    // }, []);

    let login = () => {     

        setLoader(true)
        signInWithEmailAndPassword(auth, userData.email, userData.password).then((userCredential) => {
            setError(false)
            let user = userCredential.user
            database.ref('liftAdmin').once('value', (snap) => {
                let adminUid = {}
                let adminData = snap.val().woj
                for (let email in adminData) {
                    adminUid[`${adminData[email].email}`] = adminData[email].email
                }
                if (adminUid.hasOwnProperty(user.email)) {
                    setLoader(false)
                    dispatch(loginSuccess())
                    navigate('/team');
                } else {
                    setLoader(false)
                    setError(true)
                }
            })

        }).catch((error) => {
            setLoader(false)
            setError(true)
        })
    }

    let handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    }

    return (

        <div className='loginMain'>
            {loader ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </Box>
            ) :
                (
                    <div className='loginpage'>
                        <div className='singcolour'>
                            <div>Sign in</div>
                        </div >
                        <input
                            type="email"
                            className="sign"
                            value={userData.email}
                            onChange={(event) => {
                                let email = event.target.value
                                setUserData({ ...userData, email: email })
                            }}
                            placeholder='Email'>
                        </input>
                        <input
                            type="password"
                            className="sign"
                            value={userData.password}
                            onChange={(event) => {
                                let password = event.target.value
                                setUserData({ ...userData, password: password })
                            }}
                            placeholder='Password'>
                        </input>

                        <div>
                            <button
                                className="loginButton"
                                onKeyPress={handleKeyPress}
                                onClick={login}>
                                Login</button>
                        </div>
                        {
                            (error === true) ? (
                                < div
                                    id="error-message"
                                    className="text-center errorMessage"
                                >Incorrect email or password</div>
                            ) : (
                                <></>
                            )
                        }

                    </div >
                )

            }

        </div >
    );
}

export default Login;