import { Routes, Route, Navigate } from "react-router-dom";
import TopBar from "./scence/global/TopBar";
import Team from "./scence/team";
import towerd from "./scence/towerD";
import Login from "./scence/login"
import { useState } from "react";
import { ProtectedRoute } from "./route/protected";
import { BrowserRouter as Router } from 'react-router-dom'

// import { useEffect } from "react";
// import firebase from "./Config";
// import { useState } from "react";

// const database = firebase.database();



function App() {

  return (
    <div className="app">
      <main className="content">
        <Routes>
          <Route element={<ProtectedRoute />} >
            <Route path="/team" element={<Team />} />
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
    
      </main>
    </div >
  );
}

export default App;
