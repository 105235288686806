import React from "react";
import NavigationTwoToneIcon from '@mui/icons-material/NavigationTwoTone';
import firebase from "../../Config";
import '../../index.css';
import { useDispatch, useSelector } from "react-redux";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { towerNameChanges } from '../../state/authAction'
import supimg from '../../assets/Up-01.png'
import sdownimg from '../../assets/down-01.png'
import door from '../../assets/Lift-01.png'
import fire from '../../assets/fire-01.png'
import status from '../../assets/service-01.png'
import { Box } from '@mui/material'
import Header from "../../components/Header"
import { useState } from "react";
import { useEffect } from "react";
const database = firebase.database();



const TowerD = () => {
  let dispatch = useDispatch()
  let towerName = useSelector((state) => state.auth.towerName)
  const handleChange = (event) => {
    console.log("onchange event:tower D", event.target.value)
    dispatch(towerNameChanges(event.target.value))

  };

  let storeTowerValue = useSelector((state) => state.auth.towerName)
  console.log("storeTowerValue:in Team", storeTowerValue)
  let [search, setSearch] = useState("")
  let [lift, setLift] = useState([
    {
      'currentFloor': 'H',
      'deviceState': true,
      'doors': 'open',
      'hardwareId': 'A001701927455843',
      'liftDirection': 'UP',
      'towerName': 'towerC',
      'fireAlarm': '-',
      'status': '-'
    },
    {
      'currentFloor': 'H',
      'deviceState': false,
      'doors': 'closed',
      'hardwareId': 'A001701927455844',
      'liftDirection': 'UP',
      'towerName': 'towerC',
      'fireAlarm': '-',
      'status': '-'
    }])

  useEffect(() => {

    const fetchData = async () => {
      try {

        /////////////////// database query ////////////////
        // example  database.ref('node name')////////

        database.ref('liftDetails/').orderByChild('towerName').equalTo('towerD').on('value', (data) => {
          console.log("tower D Data:", data.val())
          let allLiftData = []
          let lift1Data = data.val()
          for (let liftId in lift1Data) {
            let liftData = lift1Data[liftId]
            allLiftData.push(liftData)
          }
          // console.log("allLiftData:", allLiftData)

          setLift((prevLiftData) => {
            prevLiftData = allLiftData
            // console.log("prevLiftData:", prevLiftData[0])
            return prevLiftData
          })

        })
      } catch (error) {
        console.log("-----error-----", error);
      }
    };

    fetchData();

  }, []);



  return (
    <Box m="20px">
      <Box padding="2px"
      >
        <div className="lift-box">
          <div className="titalClassD">
            <div className="buttonTowerClass">
              <div>
                <Select
                  id="demo-simple-select"
                  value={towerName}
                  onChange={handleChange}
                >
                  <MenuItem value={'C'}>TOWER C</MenuItem>
                  <MenuItem value={'D'}>TOWER D</MenuItem>
                </Select>
              </div>
            </div>

          </div>

          <div className="liftHeading">
            <div className="buttonLiftName">
              <div>
                PASSENGER
                {/*   ____44 hardwareId,  lift become  PASSENGER*/}
              </div>
            </div>
            <div className="buttonLiftName">
              <div>
                SERVICE
                {/*   ____43 hardwareId,  lift become  SERVICE*/}

              </div>
            </div>

          </div>

          <div className="direction">
            <div className="liftPosition">
              <div className={lift[0].deviceState === true ? "l1FloorPosition" : "l1FloorPositionOffline"}>
                <div>{lift[0]?.currentFloor}</div>
              </div>
              <div>
                <img src={lift[0].liftDirection.toLocaleLowerCase().trim() === 'up' ? supimg : sdownimg} alt="" style={{ 'width': '120px' }} />
              </div>
            </div>
            <div className="liftPosition">
              <div className={lift[1].deviceState === true ? "l1FloorPosition" : "l1FloorPositionOffline"}>
                <div>{lift[1]?.currentFloor}</div>
              </div>
              <div>
                <img src={lift[1].liftDirection.toLowerCase().trim() === 'up' ? supimg : sdownimg} alt="" style={{ 'width': '120px' }} />
              </div>
            </div>
          </div>

          <div className="mainLiftPartDetails">
            <div className="liftPartDetails1">
              <div className={lift[0].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                <div>{lift[0].doors}</div>
              </div>
              <div className="imgIcon">
                <div>
                  <img className="door" src={door} alt="" />
                </div>
              </div>
              <div className={lift[1].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                <div>{lift[1].doors}</div>
              </div>
            </div>
            <div className="liftPartDetails2">
              <div className={lift[0].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                <div>{lift[0].fireAlarm}</div>
              </div>
              <div className="imgIcon">
                <div>
                  <img className="door" src={fire} alt="" />
                </div>
              </div>
              <div className={lift[1].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                <div>{lift[1].fireAlarm}</div>
              </div>
            </div>
            <div className="liftPartDetails3">
              <div className={lift[0].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                <div>{lift[0].status}</div>
              </div>

              <div className="imgIcon">
                <div>
                  <img className="door" src={status} alt="" />
                </div>
              </div>
              <div className={lift[1].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                <div>{lift[1].status}</div>
              </div>
            </div>
          </div>

          {/* call lift  */}
          {/* <div className="callLift">
            <div>
              <button className="b1Button">B1</button>
            </div>
            <div>
              <button className="b1Button">B2</button>
            </div>
            <div>
              <button className="b1Button">G</button>
            </div>
          </div> */}

          <div className="blank"></div>
        </div>
      </Box>
    </Box >
  )
};

export default TowerD;
