import React from "react";
import firebase from "../../Config";
import Select from '@mui/material/Select';
import { towerNameChanges } from '../../state/authAction'
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import { getAuth, fetchSignInMethodsForEmail } from "firebase/auth";
import { Circles } from 'react-loader-spinner'
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import { Box, TextField } from '@mui/material'
import '../../index.css';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import TowerD from '../towerD/index'
import { Formik } from 'formik'
import * as yup from 'yup'
import supimg from '../../assets/Up-01.png'
import sdownimg from '../../assets/down-01.png'
import door from '../../assets/Lift-01.png'
import fire from '../../assets/fire-01.png'
import status from '../../assets/service-01.png'
import { Button } from '@mui/material'
import { useState, useEffect } from "react";
const database = firebase.database();
const auth = getAuth();


let initialValue = {
  assignFloor: '',
  email: '',
  assignTower: ''
}

const selectFloor = [
  {
    value: 'G',
    label: 'G',
  },
  {
    value: 'B1',
    label: 'B1',
  },
  {
    value: 'B2',
    label: 'B2',
  }
]

const Tower = [
  {
    value: 'towerC',
    label: 'Tower C'
  },
  {
    value: 'towerD',
    label: 'Tower D'
  }
]

let validationSchemes = yup.object().shape({
  assignFloor: yup.string().required("required"),
  email: yup.string().email("Invalid email").required("required"),
})


const Team = () => {
  console.log("Team com:")
  let dispatch = useDispatch()
  let towerName = useSelector((state) => state.auth.towerName)
  const handleChange = (event) => {
    dispatch(towerNameChanges(event.target.value))
  };
  const [open, setOpen] = useState(false)
  const [errormsg, seterrormsg] = useState(false)
  const [successmsg, setsuccessmsg] = useState(false)
  const [loader, setLoader] = useState(false)

  const handleClose = (value) => {
    setOpen(false);
  };
  let storeTowerValue = useSelector((state) => state.auth.towerName)
  let [lift, setLift] = useState([
    {
      'currentFloor': 'H',
      'deviceState': true,
      'doors': 'open',
      'hardwareId': 'A001701927455841',
      'liftDirection': 'UP',
      'towerName': 'towerC',
      'fireAlarm': '-',
      'status': '-'
    },
    {
      'currentFloor': 'H',
      'deviceState': false,
      'doors': 'closed',
      'hardwareId': 'A001701927455842',
      'liftDirection': 'UP',
      'towerName': 'towerC',
      'fireAlarm': '-',
      'status': '-'
    }])


  useEffect(() => {

    const fetchData = async () => {
      try {

        /////////////////// database query ////////////////
        // example  database.ref('node name')////////

        database.ref('liftDetails/').orderByChild('towerName').equalTo('towerC').on('value', (data) => {
          // console.log("data:", data.val())
          let allLiftData = []
          let lift1Data = data.val()
          for (let liftId in lift1Data) {
            let liftData = lift1Data[liftId]
            allLiftData.push(liftData)
          }
          // console.log("allLiftData:", allLiftData)

          setLift((prevLiftData) => {
            prevLiftData = allLiftData
            // console.log("prevLiftData:", prevLiftData[0])
            return prevLiftData
          })

        })
      } catch (error) {
        console.log("-----error-----", error);
      }
    };

    fetchData();

  }, []);
  let isNonMobile = useMediaQuery("(min-width:600px)");
  const handleFormSubmit = (value) => {
    console.log("----------value -----------", value)
    setLoader(true)
    fetchSignInMethodsForEmail(auth, value.email)
      .then((signInMethods) => {

        if (signInMethods.length > 0) {
          console.log("User with value.email " + value.email + " exists!");
          database.ref('standaloneUserDetails').orderByChild('email').equalTo(value.email).once('value', (snap) => {
            console.log("snap:------", snap.val())
            let userData = snap.val()
            for (let uid in userData) {
              if (uid !== undefined && uid !== null) {
                // console.log("email:uid", uid, userData[uid])
                database.ref('liftUserDetails/' + uid + '/').update({
                  floorAssigned: value.assignFloor.toString().toUpperCase(),
                  uid: uid,
                  towerName: value.assignTower,
                  email: userData[uid].email
                }).then(() => {
                  setLoader(false)
                  setsuccessmsg(true);
                  setTimeout(() => {
                    setsuccessmsg(false)
                  }, 5000);
                })
              } else {
                console.log("uid is null:", uid)
              }
            }
          });
        } else {
          setLoader(false)
          seterrormsg(true)
          setTimeout(() => {
            seterrormsg(false)
          }, 5000);
        }
      })
      .catch((error) => {
        console.error("Error checking user existence:", error);
      });

    // signInWithEmailAndPassword(auth, value.email).then((userCredential) => {
    //   seterrormsg(false)
    //   let user = userCredential
    //   console.log("userData:", user)

    // }).catch((error) => {
    //   seterrormsg(true)
    // })


  }

  let createUser = () => {
    setOpen(true);
  }
  return (
    (storeTowerValue === 'D' ? <TowerD /> :
      < Box m="20px" >
        {/* <Header /> */}
        <Box padding="2px"
        >
          <div className="lift-box">
            <div className="titalClass">
              <div>

              </div>
              <div className="buttonTowerClass">
                <Select
                  className="custom-select"
                  id=""
                  value={towerName}
                  onChange={handleChange}
                >
                  <MenuItem value={'C'}>TOWER C</MenuItem>
                  <MenuItem value={'D'}>TOWER D</MenuItem>
                </Select>
              </div>
              <div className="addiconcss" onClick={createUser}>
                <PersonAddTwoToneIcon />
              </div>
              <Dialog onClose={handleClose} open={open} className='dailogMain'>
                <DialogTitle>Assigne Floor</DialogTitle>
                <List sx={{ pt: 0 }}>

                  <ListItem disableGutters>
                    <Formik
                      onSubmit={handleFormSubmit}
                      initialValues={initialValue}
                      validationSchema={validationSchemes}
                    >
                      {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit} >
                          <Box display="grid" gap="30px" gridTemplateColumns="repeat(4,minmax(0,1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, }}

                          >
                            <TextField
                              fullWidth
                              variant='filled'
                              id='email'
                              type=''
                              label='Email Address'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name='email'
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              sx={{ gridColumn: "span 2" }}

                            />
                            <TextField
                              fullWidth
                              variant='filled'
                              id='assignFloor'
                              type='text'
                              label='Assign Floor'
                              select
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.assignFloor}
                              name='assignFloor'
                              error={!!touched.assignFloor && !!errors.assignFloor}
                              helperText={touched.assignFloor && errors.assignFloor}
                              sx={{ gridColumn: "span 2" }}
                            >
                              {selectFloor.map((gender) => (
                                <MenuItem key={gender.value} value={gender.value}>
                                  {gender.label}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              fullWidth
                              variant='filled'
                              id='assignTower'
                              type='text'
                              label='Assign Tower'
                              select
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.assignTower}
                              name='assignTower'
                              error={!!touched.assignTower && !!errors.assignTower}
                              helperText={touched.assignTower && errors.assignTower}
                              sx={{ gridColumn: "span 2" }}
                            >
                              {Tower.map((gender) => (
                                <MenuItem key={gender.value} value={gender.value}>
                                  {gender.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                          {errormsg && (
                            <div className='errormsg'>
                              <p>Enter valid Email Id</p>
                            </div>
                          )}
                          {successmsg && (
                            <div className='successmsg'>
                              <p>Floor Assign Succefully</p>
                            </div>
                          )}
                          <Box display="flex" justifyContent="end" mt="20px">
                            <Button type='submit' color="secondary" variant='contained'>
                              ASSIGN
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </ListItem>
                  {loader &&
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </Box>
                  }
                </List>
              </Dialog>
            </div>
            <div className="liftHeading">
              <div className="buttonLiftName">
                <div>
                  SERVICE
                </div>
              </div>
              <div className="buttonLiftName">
                <div>
                  PASSENGER
                </div>
              </div>

            </div>

            <div className="direction">
              <div className="liftPosition">
                <div className={lift[0].deviceState === true ? "l1FloorPosition" : "l1FloorPositionOffline"}>
                  <div>{lift[0]?.currentFloor}</div>
                </div>
                <div>
                  <img src={lift[0].liftDirection.toLocaleLowerCase().trim() === 'up' ? supimg : sdownimg} alt="" style={{ 'width': '120px' }} />
                </div>
              </div>
              <div className="liftPosition">
                <div className={lift[1].deviceState === true ? "l1FloorPosition" : "l1FloorPositionOffline"}>
                  <div>{lift[1]?.currentFloor}</div>
                </div>
                <div>
                  <img src={lift[1].liftDirection.toLowerCase().trim() === 'up' ? supimg : sdownimg} alt="" style={{ 'width': '120px' }} />
                </div>
              </div>
            </div>

            <div className="mainLiftPartDetails">
              <div className="liftPartDetails1">
                <div className={lift[0].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                  <div>{lift[0].doors}</div>
                </div>
                <div className="imgIcon">
                  <div>
                    <img className="door" src={door} alt="" />
                  </div>
                </div>
                <div className={lift[1].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                  <div>{lift[1].doors}</div>
                </div>
              </div>
              <div className="liftPartDetails2">
                <div className={lift[0].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                  <div>{lift[0].fireAlarm}</div>
                </div>
                <div className="imgIcon">
                  <div>
                    <img className="door" src={fire} alt="" />
                  </div>
                </div>
                <div className={lift[1].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                  <div>{lift[1].fireAlarm}</div>
                </div>
              </div>
              <div className="liftPartDetails3">
                <div className={lift[0].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                  <div>{lift[0].status}</div>
                </div>

                <div className="imgIcon">
                  <div>
                    <img className="door" src={status} alt="" />
                  </div>
                </div>
                <div className={lift[1].deviceState === true ? "liftDoorStatus" : "liftDoorStatusOffline"}>
                  <div>{lift[1].status}</div>
                </div>
              </div>
            </div>

            {/* call lift */}
            <div className="callLift">
              <div>
                <button className="b1Button">B1</button>
              </div>
              <div>
                <button className="b1Button">B2</button>
              </div>
              <div>
                <button className="b1Button">G</button>
              </div>
            </div>

            <div className="blank"></div>
          </div>
        </Box>
      </Box >
    )
  )
};

export default Team;
